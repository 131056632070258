@import 'media-queries';
@import 'sizes';
@import 'reset';
@import 'colors';
@import 'text';

body {
  background: $white;
  min-height: 100vh;
  width: 100%;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}

.spinner {
  animation: spin 4s linear infinite;
}
