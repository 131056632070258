// @import '../../css/utils.scss';

.home {
  background-color: transparent;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // padding: 16px 142px;

  // .kpi-cards {
  //   display: flex;
  //   flex-flow: row nowrap;

  //   align-items: center;
  //   justify-content: space-between;
  // }

  // .stream {
  //   align-self: center;
  //   justify-self: center;
  // }
}

.kpiContainer {
  background-color: transparent;
}

.blockContainer {
  // background-color: yellow;
  background-color: transparent;
}

.transactionContainer {
  background-color: transparent;
}
