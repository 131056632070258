// #nav {
//   position: absolute;
//   top: 0;
//   width: 300px;
// }

.prev {
  // display: inline-block;
  // position: absolute;
  // left: 0;
  // background-color: #ceaaaa;
  // padding: 5px;
  cursor: pointer;
  top: 50%;
  font-weight: bold;
  margin-top: 6rem;
}

.next {
  // position: absolute;
  // right: 0;
  // display: inline-block;
  // background-color: #ceaaaa;
  // padding: 5px;
  cursor: pointer;
  top: 50%;
  font-weight: bold;
  margin-top: 6rem;
}

.stream-navigation-next, .stream-navigation-prev {
  cursor: pointer;
}
