@import '../../sass/common';

// Header
.hero {
  background-color: $navbar-background-color;
  z-index: 999;

  position: sticky;
  top: 0;
  left: 0;

  min-height: 3.5rem;

  // .hero-head {
    // .container {
    //   margin-left: 1rem;
    //   max-width: 100rem;
    // }
  // }

  .navbar {
    .navbar-start {
      // min-width: 70%;
      width: 100%;
    }
    // .navbar-brand {
      // margin-right: 0.5rem;
    // }

    #navbarBasicExample {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

    .navbar-burger {
      &:hover {
        background-color: $grey-dark;
        color: $white-dark;
      }

      &.is-active {
        background-color: $grey-dark;
        color: $white-dark;
        margin-bottom: 0;

        &:hover {
          background-color: $grey-dark;
          color: $white-dark;
        }

      }
    }
  }

  .navbar-item {
    font-size: 1rem;

    &.has-text-brand-light {
      &:hover {
        background-color: $brand-light;
        color: $grey-dark;
      }
    }
  }
}
