@import '../../sass/common';

// Footer section
footer.footer {
  background-image: url('../../img/footer_curve.png');
  background-size: cover;
  background-position: top;
  background-repeat: repeat-x;

  padding-top: 7rem;
  background-color: white;

  margin-top: auto;

  .content {
    .contact-us-row {
      .contact-us-item {
        color: $contact-us-color;
        margin-top: 1rem;
        margin-right: 1rem;
        &:hover {
          color: $contact-us-color-hover;
        };
      }
    }

    .adress-container {
      margin-bottom: 1.5rem;
      margin-top: 4rem;
      .address-row {
        margin-bottom: 0rem;
      }
    }

    p.has-text-brand-light {
      a {
        &:hover {
          color: $contact-us-color-hover;
        };
      }
    }

    .subsection-title {
      margin-bottom: 2rem;
    }
  }

  // Footer && subscribe section
  .news-letter-box-shadow {
    box-shadow: 0.05rem 0.05rem 1rem 0.05rem $brand-light;

    &.is-text {
      border-width: 0;
      padding-left: 1rem;

      &.is-desktop {
        height: 2.5rem;
      }

      &.is-touch {
        height: 3rem;
        margin-top: 1rem;
      }
    }

    &.is-rounded-left {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
    }

    &.is-rounded-right {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }

    &.is-button {
      border-width: 0;
      font-weight: bold;

      &:hover {
        color: $white-light;
      }

      &.button-subscribe-touch {
        height: 3rem;
        margin-top: 1rem;
        border-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: none;
        color: $white-light;
      }

      &.button-subscribe-desktop {
        height: 2.5rem;
      }
    }
  }

  .field {
    p.help {
      width: 200%;
      padding: 0.5rem;
      margin-top: 0.5rem;
      color: $white-light;
    }
  }

}
