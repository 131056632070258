// One Explorer Colors
$white: #ffffff;
$black: #000000;
$turquoise-blue: #00b9d1;
$light-greenish-blue: #5df5cf;
$charcoal-grey: #384243;
$gunmetal: #575b5b;
$light-grey-blue: #adb1b2;
$light-grey: #d9dddd;
$light-greenish-blue-two: #5ef4ce;
$dirty-blue: #3a8c9e;
$seafoam-blue: #6cc0cd;
$light-aqua: #97fae9;
$duck-egg-blue: #ebfafc;
$dirty-egg-blue: #bae0e5;

// colors from Sasha
$brand-dark: #008DA0;
$brand-light: #00F2D0;
$brand-lighter: #DEFDF9;

$white-light: #FBFBFE;
$white-dark: #F3F3F3;

$grey-lighter: #CCC;
$grey-light: #9B9B9B;
$grey-dark: #384243;
$black: #000000;
$linked-in: #0077b5;

:export {
  lightAqua: $light-aqua;
  lightGreenishBlue: $light-greenish-blue;
  lightGreenishBlueTwo: $light-greenish-blue-two;
  dirtyBlue: $dirty-blue;
  white: $white;
  charcoalGrey: $charcoal-grey;
}
