html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/*!
 * !!! PARTIAL !!! Bootstrap Reboot v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box; }

@-ms-viewport {
  width: device-width; }

body {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

a {
  text-decoration: none;
  background-color: transparent; }

img {
  vertical-align: middle;
  border-style: none; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

[hidden] {
  display: none !important; }

:export {
  lightAqua: #97fae9;
  lightGreenishBlue: #5df5cf;
  lightGreenishBlueTwo: #5ef4ce;
  dirtyBlue: #3a8c9e;
  white: #ffffff;
  charcoalGrey: #384243; }

body {
  background: #ffffff;
  min-height: 100vh;
  width: 100%; }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.spinner {
  animation: spin 4s linear infinite; }

.container {
  overflow-x: scroll; }
  .container table {
    width: 100%;
    border: 3px solid #3a8c9e; }
  .container thead tr {
    background-color: #384243;
    color: #ffffff; }
  .container tbody tr:nth-child(odd) {
    background-color: #ebfafc; }
  .container td,
  .container th {
    font-size: 12px;
    padding: 10px 20px; }
  .container th {
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    color: #FBFBFE; }

.pagination-table {
  display: flex;
  flex-direction: column; }

.paginator {
  margin: 25px auto;
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: center; }

.hexagon {
  width: 37.5px;
  height: 21.75px;
  background: #ebfafc;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  min-width: 37.5px; }
  .hexagon a {
    color: #384243;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 600; }

.hexagon:before {
  content: '';
  position: absolute;
  top: -10.5px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 18.75px solid transparent;
  border-right: 18.75px solid transparent;
  border-bottom: 10.82px solid #ebfafc; }

.hexagon:after {
  content: '';
  position: absolute;
  bottom: -10.5px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 18.75px solid transparent;
  border-right: 18.75px solid transparent;
  border-top: 10.82px solid #ebfafc; }

.active {
  background: #bae0e5; }
  .active a {
    color: #ffffff; }
  .active:before, .active:after {
    border-top-color: #bae0e5;
    border-bottom-color: #bae0e5; }

a.externalUrl {
  color: #008DA0; }
