.rc-steps {
  font-size: 0;
  width: 100%;
  line-height: 1.5;
  display: flex;
}
.rc-steps, .rc-steps * {
  box-sizing: border-box;
}
.rc-steps-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  flex: 1;
  overflow: hidden;
}
.rc-steps-item:last-child {
  flex: none;
}
.rc-steps-item:last-child .rc-steps-item-tail, .rc-steps-item:last-child .rc-steps-item-title:after {
  display: none;
}
.rc-steps-item-icon, .rc-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.rc-steps-item-icon {
  border: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 26px;
  font-size: 14px;
  margin-right: 8px;
  transition: background-color 0.3s, border-color 3s;
}
.rc-steps-item-icon > .rc-steps-icon {
  line-height: 1;
  top: -1px;
  color: #384243;
  position: relative;
}
.rc-steps-item-icon > .rc-steps-icon.rcicon {
  font-size: 12px;
  position: relative;
  top: -2px;
}
.rc-steps-item-tail {
  position: absolute;
  left: 0;
  width: 100%;
  top: 10px;
  padding: 0 10px;
}
.rc-steps-item-tail:after {
  content: '';
  display: inline-block;
  background: #ebfafc;
  height: 7px;
  border-radius: 1px;
  width: 100%;
  transition: background 3s;
}
.rc-steps-item-content {
  margin-top: 3px;
}
.rc-steps-item-title {
  font-size: 14px;
  margin-bottom: 4px;
  color: #666;
  font-weight: bold;
  display: inline-block;
  padding-right: 10px;
  position: relative;
}
.rc-steps-item-title:after {
  content: '';
  height: 1px;
  width: 1000px;
  background: #ebfafc;
  display: block;
  position: absolute;
  top: 0.55em;
  left: 100%;
}
.rc-steps-item-description {
  font-size: 12px;
  color: #999;
}
.rc-steps-item-wait .rc-steps-item-icon {
  border-color: #ebfafc;
  background-color: #ebfafc;
}
.rc-steps-item-wait .rc-steps-item-icon > .rc-steps-icon {
  color: #384243;
}
.rc-steps-item-wait .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #ccc;
}
.rc-steps-item-wait .rc-steps-item-title {
  color: rgba(0, 0, 0, .43);
}
.rc-steps-item-wait .rc-steps-item-title:after {
  background-color: #ebfafc;
}
.rc-steps-item-wait .rc-steps-item-description {
  color: rgba(0, 0, 0, .43);
}
.rc-steps-item-wait .rc-steps-item-tail:after {
  background-color: #ebfafc;
}
.rc-steps-item-process .rc-steps-item-icon {
  border-color: #00b9d1;
  background-color: #fff;
}
.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon {
  color: #384243;
}
.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #384243;
}
.rc-steps-item-process .rc-steps-item-title {
  color: rgba(0, 0, 0, .65);
}
.rc-steps-item-process .rc-steps-item-title:after {
  background-color: #ebfafc;
}
.rc-steps-item-process .rc-steps-item-description {
  color: rgba(0, 0, 0, .65);
}
.rc-steps-item-process .rc-steps-item-tail:after {
  background-color: #00b9d1;
}
.rc-steps-item-process .rc-steps-item-icon {
  background: #00b9d1;
}
.rc-steps-item-process .rc-steps-item-icon > .rc-steps-icon {
  color: #fff;
}
.rc-steps-item-finish .rc-steps-item-icon {
  border-color: #384243;
  background-color: #384243;
}
.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon {
  color: #fff;
}
.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #384243;
}
.rc-steps-item-finish .rc-steps-item-title {
  color: rgba(0, 0, 0, .43);
}
.rc-steps-item-finish .rc-steps-item-title:after {
  background-color: #384243;
}
.rc-steps-item-finish .rc-steps-item-description {
  color: rgba(0, 0, 0, .43);
}
.rc-steps-item-finish .rc-steps-item-tail:after {
  background-color: #384243;
}
.rc-steps-item-error .rc-steps-item-icon {
  border-color: #f50;
  background-color: #fff;
}
.rc-steps-item-error .rc-steps-item-icon > .rc-steps-icon {
  color: #f50;
}
.rc-steps-item-error .rc-steps-item-icon > .rc-steps-icon .rc-steps-icon-dot {
  background: #f50;
}
.rc-steps-item-error .rc-steps-item-title {
  color: #f50;
}
.rc-steps-item-error .rc-steps-item-title:after {
  background-color: #ebfafc;
}
.rc-steps-item-error .rc-steps-item-description {
  color: #f50;
}
.rc-steps-item-error .rc-steps-item-tail:after {
  background-color: #ebfafc;
}
.rc-steps-item.rc-steps-next-error .rc-steps-item-title:after {
  background: #f50;
}
.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item {
  margin-right: 10px;
}
.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item:last-child {
  margin-right: 0;
}
.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item-tail {
  display: none;
}
.rc-steps-horizontal:not(.rc-steps-label-vertical) .rc-steps-item-description {
  max-width: 100px;
}
.rc-steps-label-vertical .rc-steps-item {
  overflow: visible;
}
.rc-steps-label-vertical .rc-steps-item-tail {
  /* padding: 0px 24px; */
  margin-left: 48px;
}
.rc-steps-label-vertical .rc-steps-item-content {
  display: block;
  text-align: center;
  margin-top: 8px;
  width: 100px;
}
.rc-steps-label-vertical .rc-steps-item-icon {
  display: inline-block;
  margin-left: 36px;
}
.rc-steps-label-vertical .rc-steps-item-title {
  padding-right: 0;
}
.rc-steps-label-vertical .rc-steps-item-title:after {
  display: none;
}
.rc-steps-label-vertical .rc-steps-item-description {
  text-align: left;
}
