// Font-Sizes
$font-size-desktop-large-hero: 40px;
$font-size-desktop-regular-hero: 24px;
$font-size-desktop-h1: 16px;
$font-size-desktop-h2: 14px;
$font-size-desktop-h3: 14px;
$font-size-desktop-h4: 14px;
$font-size-desktop-h5: 14px;

$font-size-desktop-large-body: 24px;
$font-size-desktop-regular-body: 16px;
$font-size-desktop-small-body: 12px;

// Font-Families
$roboto-mono-font-family: 'Roboto Mono', monospace;
$poppins-font-family: 'Poppins', sans-serif;

// Font-Weights
$font-weight-light: 200;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// line-height
$line-height: 1;
