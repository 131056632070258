@charset "utf-8";

@import "./setup.scss";

html, body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

// Custom styling
.notification {
  background-color: $white-dark;
}

// Color modifiers
.has-text-brand-light {
  color: $brand-light
}

.has-text-brand-dark {
  color: $brand-dark
}

.has-text-grey-light {
  color: $grey-light
}

.has-text-grey-dark {
  color: $grey-dark
}

.has-text-white-light {
  color: $white-light
}

.has-text-white-dark {
  color: $white-dark
}

.is-brand-light {
  background-color: $brand-light
}

.is-brand-dark {
  background-color: $brand-dark
}

.is-grey-light {
  background-color: $grey-light
}

.is-grey-dark {
  background-color: $grey-dark
}

.is-white-light {
  background-color: $white-light
}

.is-white-dark {
  background-color: $white-dark
}

.is-underlined {
  text-decoration: underline
}

.button {
  &.is-brand {
    background-color: $brand-light;
    border-color: $brand-light;
  }
  &.is-brand-dark {
    background-color: $brand-dark;
    border-color: $brand-dark;
  }

  &.is-brand-gradient {
    // width: 15rem;
    background-image: linear-gradient(to left, $brand-dark, $brand-light);
    border-color: $brand-dark;
    &:hover {
      background-color: $brand-light;
      border-color: $brand-light;
      color: $white-light;
    }

  }
}

.selectable {
  user-select: text;
}

.interactable {
  -webkit-app-region: no-drag;
}

.fa-5x {
  font-size: 5em;
}

.icon {
  &.is-huge {
    height: 7em;
    width: 7em;
  }
}

.fa-huge {
  font-size: 3.33em;
}

.is-centered-by-margin {
  margin: 0 auto;
}
