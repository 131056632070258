@import '../../sass/common';

.domain {
  .header {
    margin-bottom: 20px;

    .content {
      min-height: 4rem;
      display: flex;
      width: 100%;
      align-items: center;
    }
  }

  .table {
    .tabs {
      margin-bottom: 0;
    }
  }
}

.loader {
  display: flex;
  width: 100%;
  justify-content: center;
}

.domainInfo {
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;

  .infoColumn {
    .infoItem {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: $tablet) {
        margin-bottom: 4px;
        flex-direction: column;
      }

      .itemLabelContainer {
        margin-right: 1rem;
        width: 20%;

        .itemLabel {
          @media screen and (min-width: $desktop) {
            text-align: right;
          }
        }
      }
      .itemValueContainer {
        width: 80%;
      }
    }
  }
}

// Overwriting the Tab component styling
.tabs {
  .tab {
    margin-right: 0;

    border: 2px solid $dirty-blue;
    border-bottom: none;
  }
}

a.externalUrl {
  color: $brand-dark;
}
